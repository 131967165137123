import React from 'react'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { App } from './App'

if (process.env.NODE_ENV !== 'production') {
  const originalConsoleError = console.error
  if (console.error === originalConsoleError) {
    console.error = (...args) => {
      if (
        typeof args[0] === 'string' &&
        (args[0].indexOf('[React Intl] Missing message:') === 0 ||
          args[0].indexOf('[React Intl] Cannot format message:') === 0)
      ) {
        return
      }
      originalConsoleError.call(console, ...args)
    }
  }
}

const VERSION = process.env.REACT_APP_BUILD_VERSION

if (VERSION) {
  console.log(VERSION)
}

console.log(process.env.REACT_APP_AUTH_HOST_URL)

if (process.env.REACT_APP_ROOT_APP === 'main') {
  ReactDOM.render(<App />, document.getElementById('root'))
} 

export default App
