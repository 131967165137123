import React, { lazy } from 'react'
const LayoutComponent = lazy(() => import('@develop/layout-app/dist'))
const RepricerAndLostComponent = lazy(() => import('@develop/repricer/dist'))
const  BusinessAnalyticsComponent = lazy(() => import('@develop/profit-app/dist'))
const SupportDeskComponent = lazy(() => import('@develop/ts/dist'))
const CrmComponent = lazy(() => import('@develop/crm/dist'))

export const AppsLoader = ({
  layoutComponent,
  repricerAndLostComponent,
  businessAnalyticsComponent,
  supportDeskComponent,
  crmComponent,

}) => {
    const Layout = layoutComponent || LayoutComponent
    if (!Layout) {
      console.log('Layout is not loaded')
      return null
    }
    return (
        <Layout
          repricerAndLostComponent={repricerAndLostComponent || RepricerAndLostComponent}
          businessAnalyticsComponent={businessAnalyticsComponent || BusinessAnalyticsComponent}
          supportDeskComponent={supportDeskComponent || SupportDeskComponent}
          crmComponent={crmComponent || CrmComponent}
        />
    )
}